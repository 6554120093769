
import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { AuthGuard } from './auth.guard';

export const Approutes: Routes = [
    {
        path: '',
        component: FullComponent,
        children: [
            { path: '', 
            canActivate: [AuthGuard],
            redirectTo: '/apps/todays-delivery', pathMatch: 'full' },

            {
                path: 'dashboard',
                canActivate: [AuthGuard],
                loadChildren: () => import('./dashboards/dashboard.module').then(m => m.DashboardModule)
            },
            { path: 'tables', loadChildren: () => import('./table/tables.module').then(m => m.TablesModule) },
            { path: 'apps', loadChildren: () => import('./apps/apps.module').then(m => m.AppsModule) },
        ]
    },
    {
        path: '',
        component: BlankComponent,
        children: [
            {
                path: 'authentication',
                loadChildren:
                    () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
            }
        ]
    },
    { 
        path: '**',
        redirectTo: '/authentication/404'
    }
];
