import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable  } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class InterceptorInterceptor implements HttpInterceptor {

  constructor(private spinner : NgxSpinnerService,private router: Router, private toastr: ToastrService,) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // console.log('==============called interceptor============');
    
    const token = localStorage.getItem('token'); // you probably want to store it in localStorage or something

    if (!token) {
      return next.handle(request);
    }
 
    const req1 = request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`),
    });

    return next.handle(req1).pipe(catchError((err: any) => {
      console.log(err.error.error);
      if(err.error.error == 'invalid_token'){
        localStorage.setItem('token','')
        this.router.navigate(['/authentication/login']);
        this.spinner.hide();
        this.toastr.error('Token Expired')
      }else{
        this.spinner.hide();
        console.log(err);
      }
    return new Observable<HttpEvent<any>>()
  }));
  }
}
